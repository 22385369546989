import cn from 'classnames';
import PaymentTimeLimit from './components/PaymentTimeLimit/PaymentTimeLimit.css';
import Notification from './components/Notification/Notification.css';
import Tooltip from './components/Tooltip/Tooltip.css';
import PaymentForm from './components/PaymentForm/PaymentForm.css';
import OrderStatus from './components/OrderCard/OrderStatus/OrderStatus.css';
import OrderBooking from './components/OrderCard/OrderBooking/OrderBooking.css';
import OrderPassengers from './components/OrderCard/OrderPassengers/OrderPassengers.css';
import OrderPreview from './components/OrderCard/OrderPreview/OrderPreview.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import Amenities from './components/Amenities/Amenities.css';
import PassengersDetails from './components/PassengersDetails/PassengersDetails.css';
import ResendTicketsForm from './components/ResendTicketsForm/ResendTicketsForm.css';
import AdditionalBaggage from './Baggage/AdditionalBaggage/AdditionalBaggage.css';
import Baggage from './Baggage/Baggage.css';
import BaggageTotalPriceBlock from './Baggage/BaggageTotalPriceBlock/BaggageTotalPriceBlock.css';
import UpgradeBaggageDialog from './Baggage/UpgradeBaggageDialog/UpgradeBaggageDialog.css';
import BaggageCounter from './Baggage/BaggageCounter/BaggageCounter.css';
import Checkout from './components/Checkout/Checkout.css';
import SeatMap from './components/SeatMap/SeatMap.css';
import SeatMapAirplane from './components/SeatMap/Airplane/Airplane.css';
import SeatMapPassengers from './components/SeatMap/Passengers/Passengers.css';
import SeatMapPassenger from './components/SeatMap/Passenger/Passenger.css';
import SeatMapDoubleSeat from './components/SeatMap/DoubleSeat/DoubleSeat.css';
import SeatMapDisclaimer from './components/SeatMap/Disclaimer/Disclaimer.css';
import SeatMapControls from './components/SeatMap/Controls/Controls.css';
import SeatMapSeat from './components/SeatMap/Seat/Seat.css';
import SeatMapSeatsInfo from './components/SeatMap/SeatsInfo/SeatsInfo.css';
import SeatMapTooltip from './components/SeatMap/Tooltip/Tooltip.css';
import SeatMapMobilePassenger from './components/SeatMap/MobilePassenger/MobilePassenger.css';
import SeatMapSureModal from './components/SeatMap/SureModal/SureModal.css';
import SeatMapServiceSlide from './components/SeatMap/SeatServiceSlide/SeatServiceSlide.css';
import SeatMapSeatsRecommendPopup from './components/SeatMap/SeatsRecommendPopup/SeatsRecommendPopup.css';
import Toolbar from './components/Toolbar/Toolbar.css';
import AircraftPopup from './components/AircraftPopup/AircraftPopup.css';
import SuggestionModalContent from './components/SuggestionModalContent/SuggestionModalContent.css';
import Modal from './components/Modal/Modal.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import LocationAutocomplete from './components/LocationAutocomplete/LocationAutocomplete.css';
import SearchFormDatepicker from './components/SearchFormDatepicker/SearchFormDatepicker.css';
import Passengers from './components/Passengers/Passengers.css';
import ResultsCalendar from './components/Results/Calendar/Calendar.css';
import WeekCalendarDay from './components/WeekCalendar/Day/Day.css';
import DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import DesktopFlightRoutePrice from './components/DesktopFlightRoute/FlightPrice/FlightPrice.css';
import ResultSortings from './components/Results/FlightsList/Sortings/Sortings.css';
import ActionButton from './components/ActionButton/ActionButton.css';
import CloseIcon from './components/CloseIcon/CloseIcon.css';
import Counter from './components/Counter/Counter.css';
import Meal from './components/Meal/Meal.css';
import SegmentTabs from './components/SegmentTabs/SegmentTabs.css';
import ServiceWithSegments from './components/ServiceWithSegments/ServiceWithSegments.css';
import SlideBottom from './components/SlideBottom/SlideBottom.css';
import FareGroupGrid from './components/FareGroup/FareGroupGrid/FareGroupGrid.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import Stepbar from './components/Stepbar/Stepbar.css';
import CompareFares from './components/CompareFares/CompareFares.css';
import PassengerSwitch from './components/Passenger/Switch/Switch.css';
import PassengerForm from './components/PassengerForm/PassengerForm.css';
import CheckinInitial from './components/Checkin/Initial/Initial.css';
import CheckinPassengers from './components/Checkin/Passengers/Passengers.css';
import CheckinRules from './components/CheckinRules/CheckinRules.css';
import MobileSegment from './components/MobileSegment/MobileSegment.css';
import SelectedPassenger from './components/SelectedPassenger/SelectedPassenger.css';
import RegisteredPassenger from './components/RegisteredPassenger/RegisteredPassenger.css';
import RegisteredPassengerService from './components/RegisteredPassenger/Service/Service.css';
import StatusMessage from './components/StatusMessage/StatusMessage.css';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import MobileStepbar from './components/MobileStepbar/MobileStepbar.css';
import Checkin from './components/Checkin/Checkin.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import Insurance from './components/Insurance/Insurance.css';
import Segment from './components/Segment/Segment.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import Contacts from './components/Contacts/Contacts.css';
import AdditionalServiceBanner from './components/AdditionalServiceBanner/AdditionalServiceBanner.css';
import Service from './components/Service/Service.css';
import ScheduleForm from './components/ScheduleForm/ScheduleForm.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import LoginPage from './components/LoginPage/LoginPage.css';
var theme = {
    Checkout: {
        CheckoutStyles: {
            gdsTotalPrice: Checkout.gdsTotalPrice,
            form: Checkout.form,
            orderMessages: Checkout.orderMessages
        },
        PassengersStyles: {
            passengers: Checkout.passengers
        },
        PassengersToolbar: {
            label: Checkout.passengersToolbar__label,
            toolbarCheckbox__wrapper: Checkout.toolbarCheckbox__wrapper
        }
    },
    SeatMap: {
        SeatMapStyles: {
            wings: SeatMap.wings,
            row__serviceHeader: SeatMap.row__serviceHeader,
            row__serviceHeader__wrapper: SeatMap.row__serviceHeader__wrapper,
            row__serviceHeader__serviceName: SeatMap.row__serviceHeader__serviceName,
            exit__label: SeatMap.exit__label
        },
        AirplaneStyles: {
            airplane: SeatMapAirplane.airplane,
            airplane__image: SeatMapAirplane.airplane__image,
            airplane__info: SeatMapAirplane.airplane__info,
            airplane__name: SeatMapAirplane.airplane__name,
            details: SeatMapAirplane.details,
            segment: SeatMapAirplane.segment,
            seatsDetails: SeatMapAirplane.seatsDetails,
            seatsDetails_icon: SeatMapAirplane.seatsDetails_icon
        },
        AirplaneInfoStyles: {
            sunInfo: SeatMapAirplane.sunInfo
        },
        ComponentStyles: {
            wrapper_threeServices: SeatMap.wrapper_threeServices,
            wrapper_fourServices: SeatMap.wrapper_fourServices,
            header_passengers: SeatMap.header_passengers,
            header_mobile: SeatMap.header_mobile,
            airplane: SeatMap.airplane,
            seatsInfo: SeatMap.seatsInfo
        },
        PassengersStyles: {
            together: SeatMapPassengers.together,
            recommend: SeatMapPassengers.recommend,
            recommend__wrapper: SeatMapPassengers.recommend__wrapper,
            recommend__label: SeatMapPassengers.recommend__label,
            recommend__price: SeatMapPassengers.recommend__price,
            recommend__icon: SeatMapPassengers.recommend__icon,
            passengers__button: SeatMapPassengers.passengers__button,
            passengers__button_clear: SeatMapPassengers.passengers__button_clear
        },
        Passenger: {
            item: SeatMapPassenger.item,
            item_active: SeatMapPassenger.item_active,
            item_recommend: SeatMapPassenger.item_recommend,
            item_withSeat: SeatMapPassenger.item_withSeat,
            freeSeat__text: SeatMapPassenger.freeSeat__text,
            avatar: SeatMapPassenger.avatar,
            passengerName: SeatMapPassenger.passengerName,
            payload: SeatMapPassenger.payload,
            select: SeatMapPassenger.select,
            numbers: SeatMapPassenger.numbers,
            money: SeatMapPassenger.money,
            clear: SeatMapPassenger.clear
        },
        DoubleSeat: {
            container: SeatMapDoubleSeat.container,
            switch__label: SeatMapDoubleSeat.switch__label,
            switch__icon: SeatMapDoubleSeat.switch__icon,
            description: SeatMapDoubleSeat.description
        },
        SeatMapDisclaimerStyles: {
            disclaimer: SeatMapDisclaimer.disclaimer
        },
        Controls: {
            controls: SeatMapControls.controls,
            back: SeatMapControls.back,
            button: SeatMapControls.button
        },
        SeatStyles: {
            seat: SeatMapSeat.seat,
            seat_business: SeatMapSeat.seat_business,
            seat_occupied: SeatMapSeat.seat_occupied,
            seat_unavailable: SeatMapSeat.seat_unavailable,
            seat_unexistent: SeatMapSeat.seat_unexistent,
            seat_recommend: SeatMapSeat.seat_recommend,
            seat_recommendCurrent: SeatMapSeat.seat_recommendCurrent,
            number: SeatMapSeat.number
        },
        SeatsInfoStyles: {
            seat_free: SeatMapSeatsInfo.seat_free,
            seat_economy: SeatMapSeatsInfo.seat_economy,
            seat_business: SeatMapSeatsInfo.seat_business,
            seat_preferred: SeatMapSeatsInfo.seat_preferred,
            image: SeatMapSeatsInfo.image,
            title: SeatMapSeatsInfo.title,
            title_withDescription: SeatMapSeatsInfo.title_withDescription,
            description: SeatMapSeatsInfo.description,
            header: SeatMapSeatsInfo.header,
            seat: SeatMapSeatsInfo.seat,
            seat__info: SeatMapSeatsInfo.seat__info,
            note: SeatMapSeatsInfo.note,
            note__header: SeatMapSeatsInfo.note__header,
            additionalInfo: SeatMapSeatsInfo.additionalInfo,
            features: SeatMapSeatsInfo.features,
            feature: SeatMapSeatsInfo.feature,
            price__wrapper: SeatMapSeatsInfo.price__wrapper,
            price: SeatMapSeatsInfo.price
        },
        SeatTooltipStyles: {
            tooltip: SeatMapTooltip.tooltip,
            tooltip_recommend: SeatMapTooltip.tooltip_recommend,
            tooltip_withInfo: SeatMapTooltip.tooltip_withInfo,
            info: SeatMapTooltip.info,
            number: SeatMapTooltip.number,
            details__price: SeatMapTooltip.details__price,
            details__seatName: SeatMapTooltip.details__seatName,
            popper: SeatMapTooltip.popper
        },
        MobilePassenger: {
            passenger: SeatMapMobilePassenger.passenger
        },
        SureModal: {
            button: SeatMapSureModal.button
        },
        SeatServiceSlide: {
            serviceInfo__slider: SeatMapServiceSlide.serviceInfo__slider
        },
        SeatsRecommendPopup: {
            main: SeatMapSeatsRecommendPopup.main,
            main__icon: SeatMapSeatsRecommendPopup.main__icon,
            price__wrapper: SeatMapSeatsRecommendPopup.price__wrapper,
            money: SeatMapSeatsRecommendPopup.money,
            content: SeatMapSeatsRecommendPopup.content,
            control: SeatMapSeatsRecommendPopup.control
        }
    },
    Toolbar: {
        Toolbar: {
            toolbar: Toolbar.toolbar,
            backButton__wrapper: Toolbar.backButton__wrapper,
            button: Toolbar.button,
            price: Toolbar.price,
            miles: Toolbar.miles
        }
    },
    AircraftPopup: {
        AircraftPopupContent: {
            title: AircraftPopup.title,
            groups_itemIcon: AircraftPopup.groups_itemIcon,
            groups_itemTitle: AircraftPopup.groups_itemTitle,
            groups_itemDescription: AircraftPopup.groups_itemDescription
        },
        AircraftPopup: {
            close: AircraftPopup.close,
            paper: AircraftPopup.paper
        }
    },
    SuggestionModalContent: {
        SuggestionModalContent: {
            modal__button: SuggestionModalContent.modal__button,
            modal__button_decline: SuggestionModalContent.modal__button_decline
        }
    },
    Modal: {
        Modal: {
            modal: Modal.modal,
            modal_mobileFullScreen: Modal.modal_mobileFullScreen,
            closeIcon: Modal.modal__closeIcon
        }
    },
    SearchForm: {
        SearchForm: {
            searchForm: SearchForm.searchForm__searchForm
        },
        Value: {
            value: SearchForm.value__value,
            code: SearchForm.value__code,
            wrapper_alternative: SearchForm.value__wrapperAlternative,
            withMultiCity: SearchForm.value__withMultiCity
        },
        Datepicker: {
            date__inputWrapper: SearchForm.datepicker__dateInputWrapper,
            dates__to_focused: SearchForm.datepicker__datesToFocused,
            dates__back_focused: SearchForm.datepicker__datesBackFocused,
            alternative: SearchForm.datepicker__alternative,
            dow: SearchForm.datepicker__dow,
            alternative_dow: SearchForm.datepicker__alternativeDow,
            dates: SearchForm.datepicker__dates,
            dates__to: SearchForm.datepicker__datesTo,
            dates__back: SearchForm.datepicker__datesBack,
            highlightedDate_withPrice: SearchForm.datepicker__highlightedDateWithPrice,
            highlighted_minPrice: SearchForm.datepicker__highlightedMinPrice,
            day__price: SearchForm.datepicker__dayPrice
        },
        Passengers: {
            selector: SearchForm.passengers__selector,
            mobileTitle: SearchForm.passengers__mobileTitle
        },
        StartSearch: {
            startSearch: SearchForm.startSearch__startSearch
        },
        MultiCity: {
            caption: SearchForm.multiCity__caption
        },
        Location: {
            switcher: SearchForm.location__switcher,
            switcher_alternative: SearchForm.location__switcherAlternative
        },
        Actions: {
            addSegment: SearchForm.actions__addSegment,
            removeSegment: SearchForm.actions__removeSegment
        },
        Segment: {
            alternative: SearchForm.segment__alternative,
            withMultiCity: SearchForm.segment__withMultiCity,
            cell_location: SearchForm.segment__cellLocation,
            input: SearchForm.segment__input,
            cell_date: SearchForm.segment__cellDate
        }
    },
    LocationAutocomplete: {
        Group: {
            group__label: LocationAutocomplete.group__groupLabel
        },
        Option: {
            label: LocationAutocomplete.option__label,
            code: LocationAutocomplete.option__code,
            option: LocationAutocomplete.option__option
        },
        Dropdown: {
            allDirections__button: LocationAutocomplete.dropdown__allDirectionsButton
        },
        DirectionsDialog: {
            label: LocationAutocomplete.directionsDialog__label,
            country_active: LocationAutocomplete.directionsDialog__countryActive,
            country: LocationAutocomplete.directionsDialog__country,
            countries__list: LocationAutocomplete.directionsDialog__countriesList,
            city: LocationAutocomplete.directionsDialog__city,
            iata: LocationAutocomplete.directionsDialog__iata
        }
    },
    SearchFormDatepicker: {
        DayStyles: {
            day_withPrice: SearchFormDatepicker.dayStyles__dayWithPrice,
            day_selected: SearchFormDatepicker.dayStyles__daySelected,
            day_highlighted: SearchFormDatepicker.dayStyles__dayHighlighted,
            day_inPeriod: SearchFormDatepicker.dayStyles__dayInPeriod,
            day_startPeriod: SearchFormDatepicker.dayStyles__dayStartPeriod,
            day_endPeriod: SearchFormDatepicker.dayStyles__dayEndPeriod,
            day_startHover_withPrice: SearchFormDatepicker.dayStyles__dayStartHoverWithPrice,
            day_endHover_withPrice: SearchFormDatepicker.dayStyles__dayEndHoverWithPrice,
            day_startHover_withprice: SearchFormDatepicker.dayStyles__dayStartHoverWithprice,
            day_today: SearchFormDatepicker.dayStyles__dayToday,
            day_weekend: SearchFormDatepicker.dayStyles__dayWeekend,
            day__price: SearchFormDatepicker.day__price
        },
        MonthHeaderStyles: {
            monthHeader__select_month: SearchFormDatepicker.monthHeaderStyles__selectMonth,
            monthHeader__select_year: SearchFormDatepicker.monthHeaderStyles__selectYear
        },
        MonthStyles: {
            month: SearchFormDatepicker.monthStyles__month,
            week: SearchFormDatepicker.monthStyles__week,
            week_withPrice: SearchFormDatepicker.monthStyles__weekWithPrice
        },
        WeeklyHeaderStyles: {
            dayOfWeekName_withPrices: SearchFormDatepicker.weeklyHeaderStyles__dayOfWeekNameWithPrices
        },
        SearchFormDatepickerStyles: {
            footer__done: SearchFormDatepicker.searchFormDatepickerStyles__footerDone,
            dialog__bar: SearchFormDatepicker.searchFormDatepickerStyles__dialogBar,
            dialog__dates: SearchFormDatepicker.searchFormDatepickerStyles__dialogDates,
            dialog__activeDate: SearchFormDatepicker.searchFormDatepickerStyles__dialogActiveDate,
            footer__clear: SearchFormDatepicker.searchFormDatepickerStyles__footerClear,
            footer__buttons: SearchFormDatepicker.searchFormDatepickerStyles__footerButtons,
            content: SearchFormDatepicker.content
        },
        MonthsWrapperStyles: {
            wrapper: SearchFormDatepicker.months_wrapper,
            monthsList: SearchFormDatepicker.monthsList,
            monthsList_withPrice: SearchFormDatepicker.monthsList_withPrice
        }
    },
    Passengers: {
        PassengerStyles: {
            increase: Passengers.passengerStyles__increase,
            decrease: Passengers.passengerStyles__decrease,
            counter: Passengers.passengerStyles__counter,
            passengerType: Passengers.passengerStyles__passengerType,
            age: Passengers.passengerStyles__age
        },
        PassengersStyles: {
            item: Passengers.passengersStyles__item
        }
    },
    AdditionalBaggage: {
        PromoMessage: {
            root: AdditionalBaggage.promoMessage_root
        },
        AdditionalBaggage: {
            header: AdditionalBaggage.header
        }
    },
    Baggage: {
        Baggage: {
            iconWrapper_pointer: Baggage.iconWrapper_pointer,
            icon: Baggage.icon
        },
        BaggageIcon: {
            wrapper: Baggage.baggageIcon_wrapper
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            control: BaggageTotalPriceBlock.control,
            control__edit: BaggageTotalPriceBlock.control_edit,
            price: BaggageTotalPriceBlock.price
        }
    },
    BaggageList: {
        BaggageList: {
            add: Baggage.baggageList_add
        },
        BaggageCount: {
            root: Baggage.baggageCount_root
        }
    },
    UpgradeBaggageDialog: {
        BaggageFooter: {
            root: UpgradeBaggageDialog.baggageFooter_root,
            confirm_btn: UpgradeBaggageDialog.baggageFooter_confirm_btn,
            cancel_btn: UpgradeBaggageDialog.baggageFooter_cancel_btn,
            clear: UpgradeBaggageDialog.baggageFooter_clear,
            price_block__delimiter: UpgradeBaggageDialog.baggageFooter_price_block__delimiter
        },
        BaggageSegment: {
            root: UpgradeBaggageDialog.baggageSegment_root
        },
        BaggagePassenger: {
            number: UpgradeBaggageDialog.baggagePassenger_number,
            root: UpgradeBaggageDialog.baggagePassenger_root
        },
        UpgradeBaggageDialog: {
            close: UpgradeBaggageDialog.close
        },
        MobileBaggageSelector: {
            footer: UpgradeBaggageDialog.mobileBaggageSelector_footer
        }
    },
    BaggageCounter: {
        BaggageCounter: {
            minus: BaggageCounter.minus,
            plus: BaggageCounter.plus
        }
    },
    BaggageCard: {
        BaggageCard: {
            button: BaggageTotalPriceBlock.control
        }
    },
    IncludedBaggage: {
        IncludedBaggage: {
            upgrade_block: AdditionalBaggage.upgrade_block
        }
    },
    Results: {
        Calendar: {
            legNumber: ResultsCalendar.legNumber,
            title__cities: ResultsCalendar.title__cities,
            diagram: ResultsCalendar.diagram,
            priceGraph__content: ResultsCalendar.priceGraph__content
        },
        Sortings: {
            title: ResultSortings.title,
            currentSorting: ResultSortings.currentSorting,
            sortBy: ResultSortings.sortBy,
            flightType: ResultSortings.flightType,
            flightType__active: ResultSortings.flightType__active
        },
        Results: {
            toolbar__backButton: Toolbar.toolbar__backButton,
            header: Stepbar.results_header
        }
    },
    WeekCalendar: {
        DayStyles: {
            price: WeekCalendarDay.price,
            day_selected: WeekCalendarDay.day_selected,
            date: WeekCalendarDay.date,
            date__info: WeekCalendarDay.date__info
        },
        WeekCalendarStyles: {
            dayWrapper: WeekCalendarDay.dayWrapper,
            dayWrapper_selected: WeekCalendarDay.dayWrapper_selected
        }
    },
    DesktopFlightRoute: {
        FlightPriceStyles: {
            button: DesktopFlightRoute.flightPrice_button,
            fare_family: DesktopFlightRoutePrice.fare_family,
            price: DesktopFlightRoutePrice.price,
            price_selected: DesktopFlightRoutePrice.price_selected,
            prices__wrp: DesktopFlightRoutePrice.prices__wrp,
            price__money: DesktopFlightRoutePrice.price__money
        },
        PointStyles: {
            time: DesktopFlightRoute.point__time
        },
        SegmentStyles: {
            route__info: DesktopFlightRoute.segment_route__info
        },
        DesktopFlightRouteStyles: {
            dropdown__icon: DesktopFlightRoute.dropdown__icon
        },
        RouteArrowStyles: {
            route: DesktopFlightRoute.route,
            timeInRoute: DesktopFlightRoute.timeInRoute
        }
    },
    PaymentTimeLimit: {
        PaymentTimeLimit: {
            wrapper: PaymentTimeLimit.wrapper
        }
    },
    Notification: {
        Notification: {
            context: Notification.context,
            text: cn(Notification.text, RetrieveBooking.notification__text),
            icon: Notification.icon
        }
    },
    Tooltip: {
        Tooltip: {
            title: Tooltip.title
        }
    },
    PaymentForm: {
        PaymentForm: {
            wrapper: PaymentForm.wrapper,
            details: PaymentForm.details,
            oneMethod: PaymentForm.oneMethod,
            content__title: PaymentForm.content__title,
            cards_header: PaymentForm.cards_header,
            content: PaymentForm.content
        }
    },
    OrderCard: {
        OrderStatus: {
            root: OrderStatus.root,
            type_review: OrderStatus.type_review,
            confirmed: OrderStatus.confirmed,
            icon: OrderStatus.icon
        },
        OrderBooking: {
            root: OrderBooking.root,
            type_review: OrderBooking.type_review,
            orderCode: OrderBooking.orderCode
        },
        OrderPassengers: {
            control: OrderPassengers.control
        },
        OrderPreview: {
            inner: OrderPreview.inner,
            city: OrderPreview.city,
            main_city: OrderPreview.main_city
        },
        OrderCard: {
            openOrder: OrderCard.openOrder
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: Amenities.amenities__item
        }
    },
    PassengersDetails: {
        PassengersDetails: {
            close: PassengersDetails.close
        }
    },
    ResendTicketsForm: {
        ResendTicketsForm: {
            title: ResendTicketsForm.title,
            wrapper: ResendTicketsForm.wrapper,
            close: ResendTicketsForm.close,
            footer: ResendTicketsForm.footer,
            cancel: ResendTicketsForm.cancel
        }
    },
    SegmentTabs: {
        SegmentTabs: {
            inner: SegmentTabs.inner,
            title: SegmentTabs.title,
            wrapper: SegmentTabs.wrapper,
            segments: SegmentTabs.segments,
            alignmentStub: SegmentTabs.alignmentStub
        },
        SegmentTab: {
            segment: SegmentTabs.segment,
            segment_active: SegmentTabs.segment__active,
            checked: SegmentTabs.checked
        }
    },
    Button: {
        ActionButton: {
            button: ActionButton.button
        }
    },
    Counter: {
        Counter: {
            button: Counter.button,
            value: Counter.value,
            button_disabled: Counter.button_disabled,
            button_readonly: Counter.button_readonly
        }
    },
    CloseIcon: {
        CloseIcon: {
            wrapper: CloseIcon.wrapper
        }
    },
    ServiceWithSegments: {
        ServiceWithSegments: {
            wrapper: ServiceWithSegments.wrapper,
            title: ServiceWithSegments.title,
            service: ServiceWithSegments.service
        }
    },
    MealService: {
        List: {
            wrapper: Meal.list__wrapper,
            item: Meal.categories__item,
            item_selected: Meal.categories__item_selected,
            item__icon: Meal.categories__item__icon
        },
        CategoriesStyles: {
            wrapper: Meal.categories__wrapper,
            mobile__header: Meal.categories__mobile__header
        },
        ServiceWrapper: {
            meals: Meal.meals
        }
    },
    Meal: {
        Meal: {
            meal: Meal.meal,
            image: Meal.image,
            name: Meal.name,
            title: Meal.title,
            price: Meal.price,
            // meal_fullSize: Meal.fullscreen,
            count: Meal.count,
            popup: Meal.popup,
            meal_fullSize: Meal.meal_fullSize,
            footer: Meal.footer,
            footer__icons: Meal.footer__icons
        },
        Details: {
            wrapper: Meal.details__wrapper,
            content: Meal.details__content,
            image: Meal.details__image,
            name: Meal.details__name,
            price: Meal.details__price,
            trigger__icon: Meal.details__trigger__icon,
            counter: Meal.details__counter,
            passenger: Meal.details__passenger,
            passenger__index: Meal.details__passenger__index,
            trigger: Meal.details__trigger,
            description: Meal.details__description,
            row: Meal.details__row,
            description__row: Meal.details__description__row,
            selector: Meal.details__selector,
            totalText: Meal.details__total__text,
            clear: Meal.details__clear,
            addForAll: Meal.details__add_for_all,
            button_empty: Meal.details_button_empty,
            counters: Meal.details__counters
        }
    },
    SlideBottom: {
        SlideBottomStyles: {
            container: SlideBottom.container,
            header: SlideBottom.header
        }
    },
    FareGroup: {
        FareGroupGrid: {
            option: FareGroupGrid.option,
            small: FareGroupGrid.small,
            header: FareGroupGrid.header,
            recommended: FareGroupGrid.recommended
        },
        FareGroup: {
            fareGroup_white: FareGroup.fareGroup_white,
            option__title: FareGroup.option__title,
            option_unavailable: FareGroup.option_unavailable,
            option__icon: FareGroup.option__icon,
            upgradeOptionWrapper: FareGroup.upgradeOptionWrapper,
            option__value: FareGroup.option__value,
            option: FareGroup.option,
            button: FareGroup.button,
            fareGroup_unavailable: FareGroup.fareGroup_unavailable,
            keyFeature: FareGroup.keyFeature,
            keyFeature_paid: FareGroup.keyFeature_paid,
            fareGroup_Business: FareGroup.fareGroup_Business,
            fareGroup: FareGroup.fareGroup,
            keyFeature__value: FareGroup.keyFeature__value,
            title: FareGroup.title,
            info: FareGroup.info,
            fareGroup_recommended: FareGroup.fareGroup_recommended
        }
    },
    DesktopFlight: {
        ModalContent: {
            fareGroup__button: FareGroup.fareGroup__button,
            buttons: FareGroup.buttons,
            paper_dropdown: FareGroup.paper_dropdown,
            slider__wrp: FareGroup.slider__wrp,
            slider_allOnce: FareGroup.slider_allOnce
        }
    },
    Stepbar: {
        Step: {
            step: Stepbar.step,
            step_active: Stepbar.step_active,
            step_disabled: Stepbar.step_disabled,
            label: Stepbar.label
        },
        Stepbar: {
            stepbar: Stepbar.stepbar
        }
    },
    CompareFares: {
        CompareFares: {
            image: CompareFares.image,
            slider: CompareFares.slider
        }
    },
    DesktopSummaryFlight: {
        SummaryFlight: {
            flight: Checkout.summaryFlight
        }
    },
    Contacts: {
        ContactsStyles: {
            contacts: Contacts.contacts,
            header: Contacts.header,
            field__contact: Contacts.field__contact,
            description__header: Contacts.description__header,
            footer__inner: Contacts.footer__inner,
            footer__text: Contacts.footer__text,
            footer_login: Contacts.footer_login
        }
    },
    Passenger: {
        Switch: {
            element: PassengerSwitch.element,
            element_active: PassengerSwitch.element_active,
            element_error: PassengerSwitch.element_error,
            error: PassengerSwitch.error
        }
    },
    PassengerForm: {
        PassengerForm: {
            form: PassengerForm.form
        },
        Disabilities: {
            checkboxes: PassengerForm.checkboxes
        },
        LoyaltyGroup: {
            loyalty__desc: PassengerForm.loyalty__desc
        },
        Header: {
            header: PassengerForm.header
        },
        PopupWrapper: {
            controls: PassengerForm.popup__controls,
            cancel: PassengerForm.popup__cancel
        }
    },
    QuickSearchForm: {
        QuickSearchForm: {
            form: QuickSearchForm.form
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button_continue: MobileToolbar.button_continue,
            button_back: MobileToolbar.button_back
        }
    },
    MobileStepbar: {
        StepContentStyles: {
            content__icon: MobileStepbar.content__icon
        }
    },
    Checkin: {
        Inital: {
            wrapper: Checkin.wrapper,
            form: Checkin.form
        },
        Passengers: {
            segments: Checkin.passengers_segments
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            form__row: RetrieveBooking.form__row,
            title: RetrieveBooking.title,
            button: RetrieveBooking.button,
            button_disabled: RetrieveBooking.button_disabled
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: Segment.wrapper,
            header: Segment.header,
            wrapper_business: Segment.wrapper_business,
            city__airport: Segment.city__airport
        }
    },
    AdditionalServiceCard: {
        AdditionalServiceStyles: {
            buttonBase: Checkout.buttonBase
        }
    },
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            wrapper: AdditionalServiceBanner.wrapper,
            extrasBanner: AdditionalServiceBanner.extrasBanner,
            content: AdditionalServiceBanner.content
        }
    },
    Insurance: {
        InsuranceStyles: {
            dialog: cn(Service.dialog_insurance),
            wrapper: cn(Service.wrapper_insurance),
            insurances: Insurance.insurances,
            dialog__button_clear: Insurance.dialog__button_clear,
            dialog__button_done: Insurance.dialog__button_done
        },
        InsuranceCardStyles: {
            insurance: Insurance.insurance,
            selected: Insurance.selected,
            insurance__button: Insurance.insurance__button,
            insurance__button_selected: Insurance.insurance__button_selected,
            insurance__header: Insurance.insurance__header,
            insurance__money: Insurance.insurance__money,
            options: Insurance.options
        },
        MobileScreenStyles: {
            header: Insurance.mobileScreen_header
        }
    },
    Service: {
        DialogStyles: {
            popupHeader: Service.popupHeader
        }
    },
    AddPassenger: {
        AddPassenger: {
            addButton: AddPassenger.addButton,
            addButton__icon: AddPassenger.addButton__icon,
            form__input: AddPassenger.form__input,
            form: AddPassenger.form,
            submitButton: AddPassenger.submitButton
        }
    },
    RegisteredPassenger: {
        RegisteredPassenger: {
            header: Checkin.registeredPassenger_header,
            wrapper: Checkin.registeredPassenger_wrapper,
            footerActions: Checkin.footerActions,
            footerActions__cancel: Checkin.footerActions__cancel
        }
    },
    StatusMessage: {
        StatusMessage: {
            statusMessage__title: Checkin.statusMessage__title,
            statusMessage: Checkin.statusMessage,
            statusMessage_success: Checkin.statusMessage_success
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: Segment.mobileSegment_wrapper,
            planeIcon: Segment.mobileSegment_planeIcon
        }
    },
    Checkin: {
        Inital: {
            wrapper: CheckinInitial.wrapper,
            form: CheckinInitial.form,
            hint: CheckinInitial.hint
        },
        Passengers: {
            title: CheckinPassengers.title,
            segments: CheckinPassengers.segments,
            toolbar: CheckinPassengers.toolbar,
            toolbar__button: CheckinPassengers.toolbar__button
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            container: cn(RetrieveBooking.container, CheckinInitial.retrieveBooking),
            title: cn(RetrieveBooking.title, CheckinInitial.retrieveBooking__title),
            title__icon: RetrieveBooking.title__icon,
            button: RetrieveBooking.button,
            form__row: RetrieveBooking.form__row
        }
    },
    CheckinRules: {
        CheckinRules: {
            rules__button: CheckinRules.rules__button
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: Segment.wrapper,
            wrapper_business: Segment.wrapper_business,
            header: Segment.header,
            city__weather: Segment.city__weather,
            flightInfo__item: Segment.flightInfo__item,
            flightInfo__title: Segment.flightInfo__title,
            flightInfo__value: Segment.flightInfo__value,
            city__airport: Segment.city__airport,
            city__info: Segment.city__info,
            city__iata: Segment.city__iata,
            city__time: Segment.city__time,
            plane__icon: Segment.plane__icon,
            airline__name: Segment.airline__name,
            footer: Segment.footer,
            footer__item: Segment.footer__item,
            footer__airline: Segment.footer__airline
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: MobileSegment.wrapper,
            flightInfo: MobileSegment.flightInfo,
            flightInfo__iatas: MobileSegment.flightInfo__iatas,
            flightInfo__weather: MobileSegment.flightInfo__weather,
            flightInfo__dates: MobileSegment.flightInfo__dates,
            flightInfo__cities: MobileSegment.flightInfo__cities,
            flightInfo__time: MobileSegment.flightInfo__time,
            arrivalInfo: MobileSegment.arrivalInfo,
            fareGroup: MobileSegment.fareGroup,
            fareGroup__img: MobileSegment.fareGroup__img,
            fareGroup__info: MobileSegment.fareGroup__info,
            planeIcon: MobileSegment.planeInfo
        }
    },
    SelectedPassenger: {
        SelectedPassenger: {
            wrapper: SelectedPassenger.wrapper,
            passenger: SelectedPassenger.passenger,
            passenger__icon: SelectedPassenger.passengerIcon,
            passenger__name: SelectedPassenger.passengerName,
            passengerInfo: SelectedPassenger.passengerInfo,
            passengerInfo__item: SelectedPassenger.passengerInfo__item,
            passengerInfo__field: SelectedPassenger.passengerInfo__field,
            loyalty__link: SelectedPassenger.loyalty__link
        }
    },
    AddPassenger: {
        AddPassenger: {
            wrapper: AddPassenger.wrapper,
            form__wrapper: AddPassenger.formWrapper,
            form: AddPassenger.form,
            form__input: AddPassenger.form__input,
            addButton: AddPassenger.addButton,
            addButton__icon: AddPassenger.addButtonIcon,
            submitButton: AddPassenger.submitButton,
            passengerIcon: AddPassenger.passengerIcon
        }
    },
    RegisteredPassenger: {
        RegisteredPassenger: {
            wrapper: RegisteredPassenger.wrapper,
            header: RegisteredPassenger.header,
            passenger: RegisteredPassenger.passenger,
            seats: RegisteredPassenger.seats,
            seats__icon: RegisteredPassenger.seats__icon,
            footerActions: RegisteredPassenger.footerActions,
            footerActions__cancel: RegisteredPassenger.footerActions__cancel,
            actions__buttons: RegisteredPassenger.actions__buttons,
            appleWallet: RegisteredPassenger.appleWaller,
            downloadButton: RegisteredPassenger.downloadButton,
            downloadIcon: RegisteredPassenger.downloadIcon,
            actions__barcode: RegisteredPassenger.actions__barcode,
            actions__barcode_disabled: RegisteredPassenger.actions__barcode_disabled,
            services: RegisteredPassenger.services
        },
        SendToEmail: {
            wrapper: RegisteredPassenger.sendToEmail__wrapper,
            text: RegisteredPassenger.sendToEmail__text
        },
        Service: {
            service: RegisteredPassengerService.service,
            service__icon: RegisteredPassengerService.service__icon,
            service__info: RegisteredPassengerService.service__info,
            service__name: RegisteredPassengerService.service__name
        }
    },
    StatusMessage: {
        StatusMessage: {
            statusMessage: StatusMessage.wrapper,
            statusMessage_success: StatusMessage.statusMessage_success,
            statusMessage__title: StatusMessage.statusMessage__title,
            statusMessage__text: StatusMessage.statusMessage__text
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button_back: MobileToolbar.button_back,
            button_continue: MobileToolbar.button_continue
        }
    },
    InsurancePage: {
        OptionsList: {
            list: CheckoutAdditionalService.optionsList
        }
    },
    CheckoutAdditionalService: {
        InsuranceUpsale: {
            content: CheckoutAdditionalService.content,
            container: CheckoutAdditionalService.container,
            oldVersion: CheckoutAdditionalService.oldVersion
        }
    },
    ScheduleForm: {
        ScheduleForm: {
            container: ScheduleForm.container
        }
    },
    LoginPage: {
        RegistrationForm: {
            checkbox: LoginPage.RegistrationForm__checkbox,
            checked: LoginPage.RegistrationForm__checkbox_checked
        }
    }
};
export default theme;
